/* Styles for tablet/desktop */
/* min-width: 768px */
@media screen and (min-width: 48rem) {
  /* Body */
  * {
    font-family: Arial, Helvetica, sans-serif;
  }
  html,
  body {
    margin: 0;
    height: 100%;
    background: rgb(245, 245, 245);
    /** used for smooth transition when the btn-footer is pressed or transitioning to other parts of the page using buttons. **/
    scroll-behavior: smooth;
  }

  /* Navigation */
  nav {
    padding: 1rem;
    position: fixed;
    width: 100vw;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(1rem);
    /* adding z-index to show on top the blurred hero message. */
    z-index: 1;
  }
  nav ul {
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 0;
    padding: 0;
  }
  nav ul li {
    font-weight: bold;
    padding: 0.3rem 1rem;
  }
  nav li a {
    display: block;
    text-decoration: none;
    color: rgb(245, 245, 245);
  }
  nav li a:hover {
    color: #87cbb9;
    transition-duration: 0.5s;
  }
  nav li a:active {
    color: rgb(160, 160, 160);
  }
  .close-nav {
    display: none;
  }

  /* Hero */
  .hero {
    /* Image - adjust opacity to darken background image*/
    background: rgba(0, 0, 0, 0.2) url("../assets/images/background2.jpg");
    background-blend-mode: darken;

    /* Positioning */
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    /* align .hero-info center verically and horizontally */
    display: flex;
    align-items: center;
    justify-content: center;

    color: rgb(245, 245, 245);
  }
  /** info **/
  .hero-info {
    /* adjustments */
    padding: 1.5em;
    margin: 1em;

    /* style */
    text-align: center;
    border-radius: 3em;
    backdrop-filter: blur(0.6em);
    z-index: 0;
  }

  /* Profile Links */
  .profileLinks a:link,
  .profileLinks a:visited {
    color: rgb(245, 245, 245);
  }
  .reactIcons {
    padding: 0 0.7em;
    /* Adjust the size of the icons */
    font-size: 1.5rem;
  }
  .reactIcons:hover {
    color: #87cbb9;
    transition-duration: 0.5s;
  }
  .reactIcons:visited {
    color: rgb(245, 245, 245);
  }

  /* About */
  .about {
    background-color: rgb(38, 38, 38);
    color: rgb(245, 245, 245);
    padding: 4.5rem;
  }
  .about img {
    border-radius: 1rem;
    width: 10rem;
    /* center image */
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .about div {
    text-align: left;
  }
  .about p {
    line-height: 1.3;
  }
  .about ul {
    list-style: none;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-inline-start: 0;
  }
  .about li {
    padding: 0.4rem;
  }

  /* Schedule */
  table,
  th,
  td {
    border: 0.1rem solid #4f4557;
  }

  /* Resume */
  .resume {
    color: #444;
    padding: 3rem 1rem;
  }
  .resume h2 {
    color: black;
  }
  .resume hr {
    border: 0.1rem solid #c0c0c0;
    width: 97%;
    max-width: 68.75rem;
  }

  .grid-education {
    text-align: left;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 1rem;
  }
  .grid-education h2 {
    grid-column: 1;
    margin-bottom: 0;
  }
  .grid-education h3 {
    grid-column: 2/4;
    margin-bottom: 0;
  }
  .grid-education h4 {
    margin-top: 0;
    grid-column: 2/4;
  }

  .grid-skills {
    padding: 1rem;
    text-align: left;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(1, 1fr);
  }
  .grid-skills h2 {
    grid-column: 1;
    grid-row: 1;
  }
  .grid-skills ul {
    list-style-type: none;
    padding: 0;
    grid-column: 2/5;
  }
  .grid-skills ul li {
    padding: 0.5rem 0;
  }
  .grid-skills ul li p {
    margin: 0 0 0.3rem 0;
  }

  .grid-experience {
    padding: 1rem;
    color: #444;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
  }

  .grid-experience div h3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-items: left;
  }

  .grid-experience h2 {
    grid-column: 1;
    grid-row: 1/8;
  }
  .experience-7 {
    grid-column: 2/5;
    grid-row: 1;
  }
  .experience-6 {
    grid-column: 2/5;
    grid-row: 2;
  }
  .experience-5 {
    grid-column: 2/5;
    grid-row: 3;
  }
  .experience-4 {
    grid-column: 2/5;
    grid-row: 4;
  }
  .experience-3 {
    grid-column: 2/5;
    grid-row: 5;
  }
  .experience-2 {
    grid-column: 2/5;
    grid-row: 6;
  }
  .experience-1 {
    grid-column: 2/5;
    grid-row: 7;
  }
  /* Projects */
  .projects {
    background: #4f4557;
    color: rgb(245, 245, 245);
    padding: 3.5rem 1rem;
  }
  .projects h2 {
    margin: 0;
    padding: 1rem;
    text-align: center;
  }

  /* Project */
  /** Each individual project **/
  .project-div--desktop {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: 1fr;
  }
  .project-div--desktop a {
    padding: 0.5rem;
    grid-column: 1;
    /** Vertically align image center  **/
    display: flex;
    align-items: center;
  }
  .project-div--desktop a img {
    width: 100%;
    border-radius: 0.3rem;
  }
  .project-div--desktop a img:hover {
    box-shadow: 0.3rem 0.3rem 0.125rem #393646;
  }
  .project-div--desktop div {
    grid-column: 2/4;
    padding: 1rem;
  }
  .project-div--desktop div h3 {
    color: rgb(245, 245, 245);
  }
  .project-div--desktop div p {
    line-height: 1.4rem;
  }
  /* Contact */

  /* Footer */
  .footer {
    text-align: center;
    color: rgb(125, 125, 125);
    background: rgb(38, 38, 38);
    padding: 2rem;
    /** Adjusting for .btn-footer class **/
    padding-top: 0;
  }
  .btn-footer {
    position: relative;
    bottom: 1.75rem;
    border-radius: 3.5rem;
    width: 3.5rem;
    height: 3.5rem;
    background: #87cbb9;
  }

  /* Buttons */
  .btn {
    /* style */
    cursor: pointer;
    background: none;

    /* structure */
    border: 0.15rem solid #87cbb9;
    border-radius: 1.5rem;
    padding: 0.5rem 1rem;
    margin: 0.7rem;
    color: rgb(245, 245, 245);
    width: 7rem;
    height: 3rem;
  }

  .btn:hover {
    background: rgba(0, 0, 0, 0.2);
  }

  .btn:active {
    position: relative;
    top: 0.06rem;
    box-shadow: 0.125rem 0.125rem 0.125rem rgb(38, 38, 38);
  }

  .btn a {
    text-decoration: none;
    color: rgb(245, 245, 245);
  }

  /* Text */
  .underline {
    border-bottom: 0.2rem solid #87cbb9;
  }

  /* Other */
  #hide-mobile {
    display: none;
  }
  .boldText {
    font-weight: bold;
  }
  .center-item {
    margin: auto;
  }
  .max-width {
    /* 1100px */
    max-width: 68.75rem;
  }
}
