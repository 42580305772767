/* Styles for mobile */
/* max-witdh: 767px */
@media screen and (max-width: 47.938rem) {
  /* CSS styles for mobile devices */

  /* Body */
  * {
    font-family: Arial, Helvetica, sans-serif;
  }
  html,
  body {
    margin: 0;
    height: 100%;
    background: rgb(245, 245, 245);
    /** used for smooth transition when the btn-footer is pressed or transitioning to other parts of the page using buttons. **/
    scroll-behavior: smooth;
  }

  /* Navigation  */
  .mobile-nav {
    position: fixed;
    top: 0;
    width: 100vw;
    background: rgba(0, 0, 0, 0.4);
    /* adding z-index to show on top the blurred hero message. */
    z-index: 1;
  }

  .nav-btn {
    /** main blue color **/
    background: #87cbb9;
    position: absolute;
    top: 1rem;
    right: 1rem;
    width: 3rem;
    height: 3rem;
    border-radius: 1rem;
    border: none;
    font-size: 1.2rem;
    z-index: 2;
  }
  .hamburger-icon {
    position: absolute;
    top: 0.9rem;
    right: 0.9rem;
    color: white;
  }
  .nav-btn:active {
    filter: brightness(70%);
  }

  /** hidden navbar **/
  .nav-list {
    margin: 0;
    /* initial position. When .nav-list-active class is active, this will remove the transform property. */
    transform: translateY(-100%);
  }
  /** active navbar  **/
  .nav-list-active {
    transition: ease 0.5s;
    color: rgb(245, 245, 245);
  }
  .mobile-nav ul {
    list-style: none;
    background: #232225;
    margin: 0;
  }
  .mobile-nav li {
    font-size: 1.5em;
    padding: 0.4em;
  }
  .mobile-nav li a {
    display: block;
    text-decoration: none;
    color: rgb(245, 245, 245);
  }
  .mobile-nav li a:active {
    color: rgb(160, 160, 160);
  }
  /** Transparent button after the nav list to close the nav bar **/
  .close-nav-btn {
    width: 100vw;
    height: 100vh;
    margin: 0;
    /** add additional transparency to the button on top of the nav tag background attribute. **/
    background: rgba(0, 0, 0, 0.01);
    border: none;
  }

  /* Hero */
  .hero {
    /* Image - adjust opacity to darken background image*/
    background: rgba(0, 0, 0, 0.2) url("../assets/images/background2.jpg");
    background-blend-mode: darken;

    /* Positioning */
    height: 100vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    /* align .hero-info center verically and horizontally */
    display: flex;
    align-items: center;
    justify-content: center;

    color: rgb(245, 245, 245);
  }
  /** info **/
  .hero-info {
    /* adjustments */
    padding: 1.5em;
    margin: 1em;

    /* style */
    text-align: center;
    border-radius: 3em;
    backdrop-filter: blur(0.6em);
    z-index: 0;
  }
  .hero-text p {
    line-height: 1.5rem;
  }

  /* Profile Links */
  .profileLinks a:link,
  .profileLinks a:visited {
    color: rgb(245, 245, 245);
  }
  .reactIcons {
    padding: 0 0.7em;
    /* Adjust the size of the icons */
    font-size: 1.5rem;
  }
  .reactIcons:hover {
    color: #87cbb9;
    transition-duration: 0.5s;
  }
  .reactIcons:visited {
    color: rgb(245, 245, 245);
  }

  /* About */
  .about {
    background-color: rgb(38, 38, 38);
    color: rgb(245, 245, 245);
    padding: 3rem 1rem;
  }

  .about img {
    border-radius: 1rem;
    width: 10rem;
    /* center image */
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  .about div {
    text-align: left;
  }
  .about p {
    line-height: 1.5;
  }
  .about ul {
    list-style: none;
    padding-inline-start: 0;
  }
  .about li {
    padding: 0.4rem;
  }

  /* Schedule */
  table,
  th,
  td {
    border: 0.1rem solid #4f4557;
  }

  .schedule {
    overflow-x: auto;
    text-align: center;
    padding: 1rem;
    background: rgb(245, 245, 245);
  }

  .schedule table {
    margin: auto;
  }

  .schedule table,
  .schedule th,
  .schedule td {
    /* border outline */
    border-collapse: collapse;
  }
  .schedule th,
  .schedule td {
    padding: 0.2rem 0.7rem;
    min-width: 3rem;
  }

  /* Resume */
  .resume {
    color: #444;
    padding: 1rem 0;
  }
  .resume h2 {
    text-align: center;
    color: black;
  }
  .resume li {
    margin: 0.5rem 0;
  }
  .resume h4 {
    display: grid;
    grid-template-columns: 2fr 1fr 2fr;
  }
  .resume hr {
    border: 0.1rem solid #c0c0c0;
    max-width: 90%;
  }
  .grid-education {
    text-align: center;
    padding: 1rem;
  }
  .grid-skills {
    padding: 1rem;
  }
  .grid-skills ul {
    list-style-type: none;
    text-align: center;
    padding: 0;
  }
  .grid-skills ul li {
    padding: 0.5rem;
  }
  .grid-skills ul li p {
    margin: 0 0 0.3rem 0;
  }
  .grid-experience {
    padding: 1rem;
    text-align: center;
    color: #444;
  }
  .grid-experience h3 {
    display: flex;
    flex-direction: column;
  }
  .grid-experience h3 span:nth-child(2),
  .grid-experience h3 span:nth-child(3) {
    font-weight: normal;
  }

  /* Projects */
  .projects {
    background: #4f4557;
    text-align: center;
    color: rgb(245, 245, 245);
    padding: 2rem 1rem;
  }
  .projects h2 {
    margin: 0;
    padding: 1rem;
  }

  /* Project */
  .project-div--mobile {
    background: rgb(136, 136, 136);
    padding: 1rem;
    margin: 1rem;
    border-radius: 1rem;
  }

  .project-div--mobile img {
    height: 100%;
    width: 100%;
  }
  .project-div--mobile h3 a {
    text-decoration: none;
  }
  .project-div--mobile button {
    border: none;
    background: none;
    padding: 0.7rem 0rem;
  }
  .project-div--mobile button p {
    color: rgb(245, 245, 245);
    font-size: 1.3rem;
    margin: 0;
  }

  /* Contact */
  .contact {
    background: rgb(70, 70, 70);
    padding: 2rem;
    color: rgb(245, 245, 245);
  }
  .contact h2 {
    margin: 0;
  }

  /* Footer */
  .footer {
    text-align: center;
    color: rgb(125, 125, 125);
    background: rgb(38, 38, 38);
    padding: 2rem;
    /** Adjusting for .btn-footer class **/
    padding-top: 0;
  }
  .footer a {
    width: 0;
    height: 0;
  }
  .btn-footer {
    position: relative;
    bottom: 1.55rem;
    border-radius: 3rem;
    width: 3rem;
    height: 3rem;
    background: #87cbb9;
    color: black;
  }

  /* Buttons */
  .btn {
    /* style */
    cursor: pointer;
    background: none;

    /* structure */
    border: 0.15rem solid #87cbb9;
    border-radius: 1.5rem;
    padding: 0.5rem 1rem;
    margin: 0.7rem;
    color: rgb(245, 245, 245);
    width: 7rem;
    height: 3rem;
  }

  .btn:hover {
    background: rgba(0, 0, 0, 0.2);
  }

  .btn:active {
    position: relative;
    top: 0.1rem;
    box-shadow: 0.15rem 0.15rem 0.15rem rgb(38, 38, 38);
  }

  .btn a {
    text-decoration: none;
    color: rgb(245, 245, 245);
  }

  /* Scrollbar */
  /* body::-webkit-scrollbar {
    width: 1rem;
  }

  body::-webkit-scrollbar-thumb {
    background-color: #87cbb9;
  } */

  /* Text */
  .underline {
    border-bottom: 0.2rem solid #87cbb9;
  }

  /* Other */
  #hide-desktop {
    display: none;
  }
  .boldText {
    font-weight: bold;
  }
  .center-item {
    margin: auto;
  }
  .max-width {
    /* 1100px */
    max-width: 68.75rem;
  }
}
